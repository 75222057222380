import "./TopPlayers.css"
import React, { useEffect, useState } from "react";
import Header from "./ui/Header";
import PlayerName from "./PlayerName"
import classNames from "classnames";
import Avatar from "./Avatar";

const TopPlayers = ({ showFilters = true }) => {
  const [showProvisional, setShowProvisional] = useState(false);
  const [showGuests, setShowGuests] = useState(false);
  const [loading, setLoading] = useState(0);
  const [data, setData] = useState(null);
  useEffect(() => {
    setData(null);
    setLoading(1);
    const loadPlayerStats = async () => {
      const resp = await fetch("/api/top-ratings?" + new URLSearchParams({
        showProvisional, showGuests,
      }));
      if (resp.ok) {
        setData(JSON.parse(await resp.text()));
        setLoading(2);
      } else {
        setLoading(3);
      }
    };
    loadPlayerStats();
  }, [showGuests, showProvisional]);
  return (
    <div className="top-players">
      <h2>Top Players</h2>
      {showFilters && (
        <div className="filters">
          <input
            type="checkbox"
            id="guests"
            name="guests"
            checked={showGuests}
            onChange={() => setShowGuests(!showGuests)}
            disabled={loading === 1}
          />
          <label
            htmlFor="guests"
            disabled={loading === 1}
          >
            Show guests
          </label>
          <input
            type="checkbox"
            id="provisional"
            name="provisional"
            checked={showProvisional}
            onChange={() => setShowProvisional(!showProvisional)}
            disabled={loading === 1}
          />
          <label
            htmlFor="provisional"
            disabled={loading === 1}
          >
            Show provisional
          </label>
        </div>
      )}
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th className="player-name">Player</th>
            <th>Rating</th>
            <th>Rating Games Played</th>
          </tr>
        </thead>
        <tbody>
          {loading < 2 && (
            <tr className="loadingRow">
              <td colSpan="4">Loading...</td>
            </tr>
          )}
          {loading === 2 && data.map(({
            name, handle, guest, provisional, ratingGamesPlayed, rating
          }, index) => (
            <tr key={handle}>
              <td>{index + 1}</td>
              <td className="player-name">
                <Avatar rating={rating} />
                <PlayerName name={name} userHandle={handle} guest={guest} />
              </td>
              <td className={classNames("rating", { provisional })}
                title={provisional ? "Provisional" : undefined}
              >
                {rating}
                {provisional && (
                  <span className="label">*</span>
                )}
              </td>
              <td>{ratingGamesPlayed}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TopPlayersPage = ({ showFilters = true }) => {
  return (
    <div className="top-players-page">
      <Header />
      <TopPlayers showFilters={showFilters} />
    </div>
  );
};

export { TopPlayers };
export default TopPlayersPage;
