import React from "react";
import classNames from "classnames";
import Card from "./Card";
import orders from "../data/orders";
import "./LogEntry.css";

function LogEntry({ msg, old, player2material }) {
  const re = /:[a-z][a-z0-9_\-:]*/gi;
  const textParts = msg.split(re);
  const keywords = Array.from(msg.matchAll(re));
  keywords.push(["", ""]);
  return (
    <div className={classNames("LogEntry", { old })}>
      {keywords.map((k, index) => {
        let name = k[0].substring(1);
        let material;
        if (name.startsWith("p:")) {
          name = name.substring(2);
          material = player2material[name];
        } else {
          material = orders[name]?.material || name;
        }
        return (
          <span key={index}>
            {textParts[index]}{" "}
            {name?.length > 0 && (
              <Card key={index} card={{ name, material }} showImage={false} />
            )}
          </span>
        );
      })}
    </div>
  );
}

export default LogEntry;
