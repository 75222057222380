import "./ControlPanel.css";
import orders from "../data/orders";
import Popup from "reactjs-popup";
import classNames from "classnames";
import { option2description } from "../data/cards";

function ControlPanel({
  message,
  error,
  connected,
  reconnect,
  question,
  options,
  onOption,
  settings,
}) {
  return (
    <div className="ControlPanel">
      {message && <div className="message">{message}</div>}
      {error && <div className="error">{error}</div>}
      {!connected && <button onClick={reconnect}>Reconnect</button>}
      <div className="question">{(question || "").replace(":p:", "")}</div>
      <div className="options">
        {options.map((option, index) => {
          const opt = typeof option === "string" ? option : option[0];
          const m = orders[opt]?.material;
          let od = opt;
          if (od === "petition") {
            if (settings?.["republic?"]) {
              od = "petition2";
            } else {
              od = "petition3";
            }
          }
          const desc = option2description[od];
          return (
            <Popup
              key={index}
              trigger={
                <button
                  className={classNames("option", opt, m)}
                  disabled={!connected}
                  onClick={() => onOption(opt)}
                >
                  {opt}
                </button>
              }
              on={["hover"]}
              position="top center"
              mouseEnterDelay={1000}
              disabled={!desc}
              className={classNames("option-popup", opt, m)}
            >
              {desc && (
                <div className={classNames("option-popup", opt, m)}>
                  {desc.map((d, index) => (
                    <div key={index}>
                      {index > 0 && <div className="or">or</div>}
                      {d.title && <div className="title">{d.title}</div>}
                      <div className="description">{d.description}</div>
                    </div>
                  ))}
                </div>
              )}
            </Popup>
          );
        })}
      </div>
    </div>
  );
}

export default ControlPanel;
