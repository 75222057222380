import React from "react";
import "./Decks.css";
import classNames from "classnames";
import { cardImageForDeck } from "../card-images";

function Decks({ jacks, orders, config: { showZero } }) {
  return (
    <div className="mini-Decks">
      <div
        className={classNames("title", cardImageForDeck("orders"))}
        style={{ gridColumn: 1, gridRow: 1 }}
      />
      <div className="value" style={{ gridColumn: 1, gridRow: 2 }}>
        {orders}
      </div>
      <div
        className={classNames("title", cardImageForDeck("jacks"))}
        style={{ gridColumn: 2, gridRow: 1 }}
      />
      <div className="value" style={{ gridColumn: 2, gridRow: 2 }}>
        {jacks}
      </div>
    </div>
  );
}

export default Decks;
