import classNames from "classnames";
import "./card-images.css";

const material2cardImage = {
  "rubble": "bar",
  "wood": "circus",
  "brick": "atrium",
  "concrete": "bridge",
  "stone": "sewer",
  "marble": "palace",
  "order": "order",
};

export function cardImageForMaterial(m) {
  return classNames("card-images", material2cardImage[m]);
}

const deck2cardImage = {
  orders: "order",
  jacks: "jack",
};

export function cardImageForDeck(d) {
  return classNames("card-images", deck2cardImage[d]);
}

export function cardImageForBuilding(b) {
  return classNames("card-images", b);
}
