import React from "react";
import "./Cards.css";
import Card from "./Card.js";
import { materials } from "../data/cards.js";
import classNames from "classnames";

function Cards({ cards = null, show, withOverlay, emptyDiv, threshold }) {
  if (!cards?.length) {
    if (emptyDiv) {
      return <div className="cards empty" />;
    } else {
      return null;
    }
  } else {
    return (
      <div className="cards">
        {!threshold || cards.length <= threshold
          ? cards.map((card, index) => (
            <Card
              key={(card.deck ?? "") + (card.id ?? index)}
              card={card}
              show={show}
              withOverlay={withOverlay}
            />
          ))
          : [...materials, "order"]
            .map((m) => cards
              .filter(({ material, name }) =>
                material === m || (m === "order" && name === m))
            )
            .filter((cs) => cs.length > 0)
            .map((cs, index) => {
              const card = cs[0];
              return (
                <div className={classNames("card-with-count-wrapper", card.material || card.name)}>
                  <Card
                    key={(card.deck ?? "") + (card.id ?? index)}
                    card={card}
                    show={show}
                    withOverlay={withOverlay}
                  />
                  {cs.length > 1 && (
                    <div className={classNames("count", card.material || card.name)}>
                      x{cs.length}
                    </div>
                  )}
                </div>
              );
            })
        }
      </div>
    );
  }
}

export default Cards;
