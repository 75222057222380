import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./ui/Header";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import "./User.css";
import PlayerName from "./PlayerName";
import classNames from "classnames";
import Avatar from "./Avatar";

dayjs.extend(LocalizedFormat);

const placeToResult = (place) => {
  if (place === 1) {
    return "Win";
  } else if (place !== undefined) {
    return "Loss";
  } else {
    return "?";
  }
};

function Stats({ stats }) {
  const { ratingGamesPlayed, ratingGamesWon, ratingGamesLost,
    provisionalGamesLeft, gamesPlayed, gamesWon, gamesLost } = stats;
  const practiceGamesPlayed = gamesPlayed - ratingGamesPlayed;
  const practiceGamesWon = gamesWon - ratingGamesWon;
  const practiceGamesLost = gamesLost - ratingGamesLost;
  return (
    <div className="stats">
      <div className="rating-stats">
        <span title="Games played against at least one other human player">
          Rating games:
        </span>
        <span className="value">
          {ratingGamesPlayed}
        </span>
        <span>Wins:</span>
        <span className="value">{ratingGamesWon}</span>
        <span>Losses:</span>
        <span className="value">{ratingGamesLost}</span>
        {ratingGamesPlayed > 0 && (
          <>
            <span>Win ratio:</span>
            <span className="value">
              {ratingGamesPlayed > 0 ? (
                (ratingGamesWon / ratingGamesPlayed * 100).toFixed(1)
              ) : "0"}
              {"%"}
            </span>
          </>
        )}
        {provisionalGamesLeft > 0 && (
          <>
            <span title="First 5 games affect rating more">
              Provisional games left:
            </span>
            <span className="value">
              {provisionalGamesLeft}
            </span>
          </>
        )}
      </div>
      {practiceGamesPlayed > 0 && (
        <div className="practice-stats">
          <span title="Games played against only bots">
            Practice games:
          </span>
          <span className="value">
            {practiceGamesPlayed}
          </span>
          <span>Wins:</span>
          <span className="value">
            {practiceGamesWon}
          </span>
          <span>Losses:</span>
          <span className="value">
            {practiceGamesLost}
          </span>
          {practiceGamesPlayed > 0 && (
            <>
              <span>Win ratio:</span>
              <span className="value">
                {practiceGamesPlayed > 0 ? (
                  (practiceGamesWon / practiceGamesPlayed * 100).toFixed(1)
                ) : "0"}
                {"%"}
              </span>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default function User() {
  const params = useParams();
  const handle = decodeURIComponent(params.handle);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(0);
  const [loadingMore, setLoadingMore] = useState(0);
  useEffect(() => {
    setUserData(null);
    setLoading(1);
    const loadPlayerStats = async () => {
      const resp = await fetch("/api/user?" + new URLSearchParams({ handle }));
      if (resp.ok) {
        setUserData(JSON.parse(await resp.text()));
        setLoading(2);
      } else {
        setLoading(3);
      }
    };
    loadPlayerStats();
  }, [handle]);
  const loadMore = useCallback(async () => {
    setLoadingMore(1);
    const resp = await fetch("/api/user?" + new URLSearchParams({
      handle,
      moreHistory: userData.history.length
    }));
    if (resp.ok) {
      const moreHistory = JSON.parse(await resp.text());
      setUserData((userData) => ({
        ...userData,
        history: [
          ...userData.history,
          ...moreHistory.history,
        ],
        hasMoreHistory: moreHistory.hasMoreHistory,
      }));
      setLoadingMore(2);
    } else {
      setLoadingMore(3);
    }
  }, [handle, userData?.history.length]);
  const provisional = userData?.stats?.provisionalGamesLeft > 0;
  return (
    <div className="user">
      <Header showTopPlayers={true} />
      {loading === 1 && "loading..."}
      {loading === 2 && userData && (
        <div className="header">
          <Avatar rating={userData.stats?.rating} />
          <div className="name">
            <span className="label">Player: </span>
            {userData.user?.name}
            {userData.user?.guest && (
              <span className="label" title=""> (guest)</span>
            )}
          </div>
        </div>
      )}
      {loading === 2 && (userData && userData.stats ? (
        <>
          <div className={classNames("rating", { provisional })}>
            <span title={provisional ? "Provisional rating is not used for top players rating" : undefined}>
              Rating{provisional && " (provisional)"}:
            </span>
            <span className="value">{userData.stats.rating}</span>
          </div>
          <Stats stats={userData.stats} />
          <div className="history">
            <table className="history">
              <thead>
                <tr>
                  <th>Game</th>
                  <th>Players</th>
                  <th>Result</th>
                  <th>Rating change</th>
                </tr>
              </thead>
              <tbody>
                {userData.history?.map((h) => (
                  <tr key={h.n}>
                    <td title={dayjs(h.updatedAt).format("L LT")}>
                      <a href={`/?observe=${h.gameId}`}>
                        {dayjs(h.updatedAt).fromNow()}
                      </a>
                    </td>
                    <td>
                      {h.scoresTable.map((r, index) => (
                        <span key={r.name}>
                          {index > 0 && ", "}
                          <PlayerName name={r.name} userHandle={r.userHandle} />
                        </span>
                      ))}
                    </td>
                    <td>
                      {placeToResult(
                        h.scoresTable.find((r) => r.userHandle === userData.user.handle)
                          ?.place
                      )}
                    </td>
                    <td>{h.ratingChange}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {userData.hasMoreHistory &&
              (loadingMore !== 1 ? (
                <button onClick={loadMore}>Load more</button>
              ) : "Loading more...")}
          </div>
        </>
      ) : (
        "No finished games yet."
      ))}
    </div>
  );
}
