import React from "react";
import classNames from "classnames";
import { rating2role } from "./data/avatar";

const Avatar = ({ rating }) => (
  <div className={classNames("avatar",
    rating2role(rating))} />
);

export default Avatar;
