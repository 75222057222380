import "./PlayLayout.css";
import React from "react";
import GameOver from "../GameOver";
import Header from "../Header";
import Decks from "./Decks";
import LogPanel from "../LogPanel";
import ControlPanel from "../ControlPanel";
import Sites from "./Sites";
import Pool from "./Pool";
import Player from "./Player";
import OpenHand from "./OpenHand";
import classNames from "classnames";

function PlayLayout({
  log,
  game,
  player2material,
  message,
  error,
  connected,
  reconnect,
  question,
  options,
  onOption,
  recentLogStart,
  gameOver,
  observe,
}) {
  const config = { showZero: false };
  const pIndex = game && Math.max(game.players.findIndex(({ self }) => self), 0);
  return (
    <div className={classNames("mini-PlayLayout", {observe})}>
      <div className="scroll">
        <div className="inner-scroll">
          <Header />
          {game && (
            <>
              <div className="sites-and-decks">
                <Sites sites={game.sites} config={config} />
                <Decks
                  jacks={game.jacksDeck}
                  orders={game.ordersDeck}
                  config={config}
                />
              </div>
              <Pool pool={game.pool} config={config} />
              {!observe && <OpenHand cards={game.players[pIndex].hand} />}
              {game.players
                .slice(pIndex)
                .concat(game.players.slice(0, pIndex))
                .map((p, index) => (
                  <Player
                    key={index}
                    player={p}
                    material={player2material[p.player]}
                    scoresAccurate={game.scoresAccurate}
                    timeReceived={game.timeReceived}
                    config={config}
                  />
                ))}
            </>
          )}
        </div>
      </div>
      <div className="bottom-sticky">
        <LogPanel
          log={log}
          recentLogStart={recentLogStart}
          player2material={player2material}
        />
        <ControlPanel
          message={message}
          error={error}
          connected={connected}
          reconnect={reconnect}
          question={question}
          options={options}
          onOption={onOption}
        />
      </div>
      {gameOver && <GameOver scores={gameOver.scores} />}
    </div>
  );
}

export default PlayLayout;
