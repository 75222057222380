export const POOL = "POOL";

export const playerBackgrounds = [
  "rubble",
  "wood",
  "brick",
  "concrete",
  "stone",
  "marble",
  "iron",
  "wheat"
];
