import "./Header.css";
import React from "react";

function Header({ showTopPlayers }) {
  return (
    <header className="App-header">
      <div id="right-links">
        <div>
          <a href="/Glory_to_Rome_rules.html" target="_blank">
            Rules
          </a>
        </div>
        <div>
          <a href="/contact" target="_blank">
            Feedback
          </a>
        </div>
        {showTopPlayers && (
          <div>
            <a href="/top">
              Top players
            </a>
          </div>
        )}
      </div>
      <h1>
        <a id="home-link" href="/">
          Glory to Rome
        </a>
      </h1>
    </header>
  );
}

export default Header;
