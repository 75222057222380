import React from "react";
import "./ViewSelector.css";
import PlayerTag from "./PlayerTag";
import PoolTag from "./PoolTag";
import { POOL } from "../data/constants";
import Card from "./Card";

function ViewSelector({
  // FIXME: Replace game with specific options.
  game,
  selectedTag,
  defaultTag,
  setHoveredTag,
  setDefaultTag,
  player2material,
}) {
  const role = game?.role;
  let pIndex = game && game.players.findIndex(({ self }) => self);
  pIndex = game && pIndex >= 0
    ? ((pIndex
      + game.players.length
      - (game.players.length <= 4 ? 1 : 2)
    ) % game.players.length)
    : 0;
  return (
    <div className="sidebar" onMouseLeave={() => setHoveredTag(null)}>
      <div className="role">
        Role:{" "}
        {role && (
          <Card card={{ name: role, material: role }} showImage={false} />
        )}
      </div>
      <div
        className="tag-wrapper"
        onMouseEnter={() => setHoveredTag(POOL)}
        onClick={() => setDefaultTag(POOL)}
      >
        <PoolTag
          pool={game?.pool}
          sites={game?.sites}
          deck={game?.ordersDeck ?? 0}
          jacks={game?.jacksDeck ?? 0}
          selected={selectedTag === POOL}
          defaultSelect={defaultTag === POOL}
        />
      </div>
      {game?.players
        .slice(pIndex)
        .concat(game.players.slice(0, pIndex))
        .map((p, index) => (
          <div
            key={index}
            className="tag-wrapper"
            onMouseEnter={() => setHoveredTag(p.player)}
            onClick={() => setDefaultTag(p.player)}
          >
            <PlayerTag
              player={p}
              selected={selectedTag === p.player}
              defaultSelect={defaultTag === p.player}
              role={game.role}
              scoresAccurate={game.scoresAccurate}
              material={player2material[p.player]}
            />
          </div>
        ))}
    </div>
  );
}

export default ViewSelector;
