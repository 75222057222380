import React, { useRef, useCallback, useState, useEffect } from "react";
import "./Contact.css";
import Header from "./ui/Header";

export default function Contact() {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);
  const [status, setStatus] = useState({
    sending: false,
    error: false,
    sent: false,
  });
  const [tkn, setTkn] = useState();
  useEffect(() => {
    (async () => {
      const result = await fetch("/tkn");
      if (result.ok) {
        setTkn(await result.text());
      }
    })();
  }, [setTkn])
  const onSubmit = useCallback(
    async (event) => {
      setStatus({ sending: true, error: false, sent: false });
      event.preventDefault();
      const name = nameRef.current.value;
      const email = emailRef.current.value;
      const message = messageRef.current.value;
      const result = await fetch("/contact", {
        method: "POST",
        body: JSON.stringify({ name, email, message }),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "X-CSRF-Token": tkn,
        },
      });
      if (result.ok) {
        setStatus({ sending: false, error: false, sent: true });
      } else {
        setStatus({ sending: false, error: true, sent: false });
      }
    },
    [nameRef, emailRef, messageRef, setStatus, tkn]
  );
  return (
    <div className="contact">
      <Header />
      <h2>Contact</h2>
      <div className="form-wrapper">
        <div className="form">
          <form
            className="form"
            onSubmit={onSubmit}
            method="post"
            disabled={status.sending || status.sent}
          >
            <div className="form-control-wrapper">
              <input
                placeholder="Name"
                name="name"
                autoComplete="off"
                className="form-control"
                required
                ref={nameRef}
              />
            </div>
            <div className="form-control-wrapper">
              <input
                placeholder="Email (optional)"
                name="email"
                autoComplete="off"
                className="form-control"
                ref={emailRef}
              />
            </div>
            <div className="form-control-wrapper">
              <textarea
                placeholder="Message"
                name="message"
                autoComplete="off"
                required
                className="form-control textarea"
                ref={messageRef}
              />
            </div>
            <div className="form-control-wrapper">
              <button className="btn btn-primary btn-block" disabled={!tkn}>
                Send
              </button>
            </div>
            {status.sent && (
              <div className="success">Your message was sent.</div>
            )}
            {status.error && (
              <div className="failure">Your message was not sent.</div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
