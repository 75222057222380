
function url(s) {
  if (process.env.NODE_ENV === "development") {
    return `ws://localhost:9090${s}`;
  } else {
    let p = window.location.protocol === "https:" ? "wss:" : "ws:";
    return `${p}//${window.location.host}${s}`;
  }
}

export function connectToGame(token, observe) {
  const params = token
    ? new URLSearchParams({ id: token })
    : new URLSearchParams({ observe })
  return new WebSocket(url(`/game?${params}`));
}

export function connectToLobby() {
  return new WebSocket(url(`/lobby`));
}

export function setOnMessage(socket, onMessage) {
  socket.onmessage = onMessage && function (event) {
    onMessage(JSON.parse(event.data));
  };
  return socket;
}
