import React from "react";
import "./Card.css";
import classNames from "classnames";
import orders from "../data/orders";
import { material2role } from "../data/cards";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

export const typeTitles = {
  modifier: "Modifier",
  "in-addition": "In addition",
  "upon-completion": "Upon completion",
};

export function Effects({ order }) {
  return (
    <>
      {order.effects.map(({ type, description }, index) => (
        <div key={index} className="effect">
          <span className="type">{typeTitles[type]}</span>: {description}.
          <br />
        </div>
      ))}
    </>
  );
}

function CardTooltip({ children, disabled = false, material, name }) {
  const order = orders[name];
  return (
    <Popup
      trigger={children}
      on={["hover", "click"]}
      mouseEnterDelay={1000}
      disabled={disabled}
      className={classNames("card-popup", material)}
      keepTooltipInside={true}
    >
      {order && (
        <div className={classNames("card-popup", material)}>
          <div className="name">{order.name}</div>
          <div className="effects">
            <Effects order={order} />
          </div>
          <div className="role">Role: {order.role}.</div>
          <div className="role">Material: {order.material}.</div>
          <div className="role">Value: {order.value}.</div>
        </div>
      )}
    </Popup>
  );
}

const MemoizedCardTooltip = React.memo(CardTooltip);

function Card({
  card: { name, material = name, visibility } = null,
  show = "face",
  showImage = true,
  caption,
  withOverlay,
}) {
  const order = orders[name];
  const tip = order && `${order.name}`;
  if (!caption) {
    switch (show) {
      case "face":
        caption = name;
        break;
      case "role":
        caption = material2role[material];
        break;
      case "material":
        caption = material;
        break;
      default:
        caption = "card";
        break;
    }
  }
  return (
    <CardTooltip disabled={!order} material={material} name={name}>
      <div
        className={classNames("card", material, { ver2: showImage }, name, {
          "with-overlay": withOverlay,
          "player-visibility": visibility === "player",
        })}
        data-tip={tip}
      >
        {!showImage && caption}
        {showImage && order && (
          <div className="effects">
            <Effects order={order} />
          </div>
        )}
      </div>
    </CardTooltip>
  );
}

export { MemoizedCardTooltip };
export default React.memo(Card);
