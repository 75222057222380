const orders = {
  vomitorium: {
    name: "Vomitorium",
    role: "architect",
    material: "concrete",
    value: 2,
    effects: [
      {
        description:
          "Before performing THINKER action may discard all cards to POOL",
        type: "modifier",
        affects: ["thinker", "pool"],
        depends: ["hand"],
      },
    ],
  },
  coliseum: {
    name: "Coliseum",
    role: "merchant",
    material: "stone",
    value: 3,
    effects: [
      {
        description:
          "When performing LEGIONARY action may take opponent's client and place in VAULT as material",
        type: "in-addition",
        affects: ["vault", "legionary"],
        depends: ["opponent"],
      },
    ],
  },
  storeroom: {
    name: "Storeroom",
    role: "architect",
    material: "concrete",
    value: 2,
    effects: [
      {
        description: "All clients count as LABORERS",
        type: "modifier",
        affects: ["architect", "merchant", "craftsman", "patron", "merchant"],
        depends: ["clientele"],
      },
    ],
  },
  circus: {
    name: "Circus",
    role: "craftsman",
    material: "wood",
    value: 1,
    effects: [
      {
        description: "May play two Orders cards of same role as JACK",
        type: "modifier",
        affects: ["lead", "follow"],
        depends: ["hand"],
      },
    ],
  },
  latrine: {
    name: "Latrine",
    role: "laborer",
    material: "rubble",
    value: 1,
    effects: [
      {
        description:
          "Before performing THINKER action may discard one card to POOL",
        type: "modifier",
        affects: ["thinker", "pool"],
        depends: ["hand"],
      },
    ],
  },
  senate: {
    name: "Senate",
    role: "architect",
    material: "concrete",
    value: 2,
    effects: [
      {
        description:
          "May take opponent's JACK into HAND at end of turn in which it is played",
        type: "modifier",
        affects: ["jack"],
        depends: [],
      },
    ],
  },
  shrine: {
    name: "Shrine",
    role: "legionary",
    material: "brick",
    value: 2,
    effects: [
      {
        description: "Maximum HAND +2",
        type: "modifier",
        affects: ["hand"],
        depends: [],
      },
    ],
  },
  insula: {
    name: "Insula",
    role: "laborer",
    material: "rubble",
    value: 1,
    effects: [
      {
        description: "Maximum CLIENTELE +2",
        type: "modifier",
        affects: ["clientele"],
        depends: [],
      },
    ],
  },
  school: {
    name: "School",
    role: "legionary",
    material: "brick",
    value: 2,
    effects: [
      {
        description: "May perform one THINKER action for each INFLUENCE",
        type: "upon-completion",
        affects: [],
        depends: ["influence"],
      },
    ],
  },
  prison: {
    name: "Prison",
    role: "merchant",
    material: "stone",
    value: 3,
    effects: [
      {
        description:
          "May exchange 3 INFLUENCE for Opponent's completed STRUCTURE",
        type: "upon-completion",
        affects: ["opponent", "influence"],
        depends: ["opponent"],
      },
    ],
  },
  foundry: {
    name: "Foundry",
    role: "legionary",
    material: "brick",
    value: 2,
    effects: [
      {
        description: "May perform one LABORER action for each INFLUENCE",
        type: "upon-completion",
        affects: ["laborer"],
        depends: ["influence"],
      },
    ],
  },
  tower: {
    name: "Tower",
    role: "architect",
    material: "concrete",
    value: 2,
    effects: [
      {
        description: "May use RUBBLE in any STRUCTURE",
        type: "modifier",
        affects: ["architect", "craftsman"],
        depends: [],
      },
      {
        description:
          "May lay foundation onto out of town SITE at no extra cost",
        type: "modifier",
        affects: ["architect", "craftsman"],
        depends: [],
      },
    ],
  },
  "ludus-magna": {
    name: "Ludus Magna",
    role: "patron",
    material: "marble",
    value: 3,
    effects: [
      {
        description: "Each MERCHANT client counts as any role",
        type: "modifier",
        affects: ["laborer", "craftsman", "patron", "architect", "legionary"],
        depends: ["merchant"],
      },
    ],
  },
  forum: {
    name: "Forum",
    role: "patron",
    material: "marble",
    value: 3,
    effects: [
      {
        description: "One client of each role wins game",
        type: "modifier",
        affects: ["end-of-game"],
        depends: ["clientele"],
      },
    ],
  },
  bar: {
    name: "Bar",
    role: "laborer",
    material: "rubble",
    value: 1,
    effects: [
      {
        description: "When performing PATRON action may take client from DECK",
        type: "in-addition",
        affects: ["patron"],
        depends: ["deck"],
      },
    ],
  },
  wall: {
    name: "Wall",
    role: "architect",
    material: "concrete",
    value: 2,
    effects: [
      {
        description: "Immune to LEGIONARY",
        type: "modifier",
        affects: ["legionary", "opponent"],
        depends: [],
      },
      {
        description: "+1 VP for every two materials in STOCKPILE",
        type: "modifier",
        affects: ["vp"],
        depends: ["stockpile"],
      },
    ],
  },
  amphitheatre: {
    name: "Amphitheatre",
    role: "architect",
    material: "concrete",
    value: 2,
    effects: [
      {
        description: "May perform one CRAFTSMAN action for each INFLUENCE",
        type: "upon-completion",
        affects: ["craftsman"],
        depends: ["influence"],
      },
    ],
  },
  garden: {
    name: "Garden",
    role: "merchant",
    material: "stone",
    value: 3,
    effects: [
      {
        description: "May perform one PATRON action for each INFLUENCE",
        type: "upon-completion",
        affects: ["patron"],
        depends: ["influence"],
      },
    ],
  },
  sewer: {
    name: "Sewer",
    role: "merchant",
    material: "stone",
    value: 3,
    effects: [
      {
        description:
          "May place Orders cards used to lead or follow into STOCKPILE at end of turn",
        type: "modifier",
        affects: ["stockpile"],
        depends: ["end-of-turn"],
      },
    ],
  },
  fountain: {
    name: "Fountain",
    role: "patron",
    material: "marble",
    value: 3,
    effects: [
      {
        description:
          "When performing CRAFTSMAN action may use cards from DECK. Retain any unused cards in HAND",
        type: "modifier",
        affects: ["craftsman", "deck", "hand"],
        depends: ["deck"],
      },
    ],
  },
  basilica: {
    name: "Basilica",
    role: "patron",
    material: "marble",
    value: 3,
    effects: [
      {
        description:
          "When performing MERCHANT action may take material from HAND",
        type: "in-addition",
        affects: ["merchant", "hand"],
        depends: ["hand"],
      },
    ],
  },
  "basilica-arbitrium": {
    name: "Basilica Arbitrium",
    role: "patron",
    material: "marble",
    value: 3,
    effects: [
      {
        description:
          "When performing MERCHANT action may take material from HAND",
        type: "modifier",
        affects: ["merchant", "hand"],
        depends: ["hand"],
      },
    ],
  },
  aqueduct: {
    name: "Aqueduct",
    role: "architect",
    material: "concrete",
    value: 2,
    effects: [
      {
        description: "When performing PATRON action may take client from HAND",
        type: "in-addition",
        affects: ["patron", "hand"],
        depends: ["hand"],
      },
      {
        description: "Maximum CLIENTELE x 2",
        type: "modifier",
        affects: ["clientele"],
        depends: [],
      },
    ],
  },
  bridge: {
    name: "Bridge",
    role: "architect",
    material: "concrete",
    value: 2,
    effects: [
      {
        description:
          "When performing LEGIONARY action may take material from STOCKPILE. Ignore Palisades. May take from all opponents",
        type: "in-addition",
        affects: ["legionary", "palisades", "opponent"],
        depends: ["opponent"],
      },
    ],
  },
  atrium: {
    name: "Atrium",
    role: "legionary",
    material: "brick",
    value: 2,
    effects: [
      {
        description:
          "When performing MERCHANT action may take from DECK (do not look at card)",
        type: "modifier",
        affects: ["merchant"],
        depends: [],
      },
    ],
  },
  catacomb: {
    name: "Catacomb",
    role: "merchant",
    material: "stone",
    value: 3,
    effects: [
      {
        description: "Game ends immediately. Score as usual",
        type: "upon-completion",
        affects: ["game-over"],
        depends: [],
      },
    ],
  },
  stairway: {
    name: "Stairway",
    role: "patron",
    material: "marble",
    value: 3,
    effects: [
      {
        description:
          "When performing ARCHITECT action may add material to opponent's completed STRUCTURE to make function available to all players",
        type: "modifier",
        affects: ["architect"],
        depends: ["opponent"],
      },
    ],
  },
  scriptorium: {
    name: "Scriptorium",
    role: "merchant",
    material: "stone",
    value: 3,
    effects: [
      {
        description: "May use one MARBLE material to complete any STRUCTURE",
        type: "modifier",
        affects: ["architect", "craftsman"],
        depends: [],
      },
    ],
  },
  villa: {
    name: "Villa",
    role: "merchant",
    material: "stone",
    value: 3,
    effects: [
      {
        description:
          "When performing ARCHITECT action may complete Villa with one material",
        type: "modifier",
        affects: ["architect"],
        depends: [],
      },
    ],
  },
  gate: {
    name: "Gate",
    role: "legionary",
    material: "brick",
    value: 2,
    effects: [
      {
        description: "Incomplete MARBLE STRUCTURES provide function",
        type: "modifier",
        affects: ["marble-structures"],
        depends: [],
      },
    ],
  },
  market: {
    name: "Market",
    role: "craftsman",
    material: "wood",
    value: 1,
    effects: [
      {
        description: "Maximum VAULT +2",
        type: "modifier",
        affects: ["vault"],
        depends: [],
      },
    ],
  },
  bath: {
    name: "Bath",
    role: "legionary",
    material: "brick",
    value: 2,
    effects: [
      {
        description:
          "When performing PATRON action each client you hire may perform its action once as it enters CLIENTELE",
        type: "modifier",
        affects: ["patron"],
        depends: [],
      },
    ],
  },
  dock: {
    name: "Dock",
    role: "craftsman",
    material: "wood",
    value: 1,
    effects: [
      {
        description:
          "When performing LABORER action may take material from HAND",
        type: "in-addition",
        affects: ["laborer"],
        depends: ["hand"],
      },
    ],
  },
  "circus-maximus": {
    name: "Circus Maximus",
    role: "merchant",
    material: "stone",
    value: 3,
    effects: [
      {
        description:
          "Each client may perform its action twice when you lead of follow its role",
        type: "modifier",
        affects: ["action", "clientele"],
        depends: [],
      },
    ],
  },
  palisade: {
    name: "Palisade",
    role: "craftsman",
    material: "wood",
    value: 1,
    effects: [
      {
        description: "Immune to LEGIONARY",
        type: "modifier",
        affects: ["legionary", "opponent"],
        depends: [],
      },
    ],
  },
  palace: {
    name: "Palace",
    role: "patron",
    material: "marble",
    value: 3,
    effects: [
      {
        description:
          "May play multiple cards of same role in order to perform additional actions",
        type: "modifier",
        affects: ["action"],
        depends: [],
      },
    ],
  },
  academy: {
    name: "Academy",
    role: "legionary",
    material: "brick",
    value: 2,
    effects: [
      {
        description:
          "May perform one THINKER action after turn during which you performed CRAFTSMAN action",
        type: "modifier",
        affects: ["craftsman"],
        depends: [],
      },
    ],
  },
  statue: {
    name: "Statue",
    role: "patron",
    material: "marble",
    value: 3,
    effects: [
      {
        description: "+3 VP",
        type: "modifier",
        affects: ["vp"],
        depends: [],
      },
      {
        description: "May place Statue on any SITE",
        type: "modifier",
        affects: ["site"],
        depends: [],
      },
    ],
  },
  temple: {
    name: "Temple",
    role: "patron",
    material: "marble",
    value: 3,
    effects: [
      {
        description: "Maximum HAND +4",
        type: "modifier",
        affects: ["hand"],
        depends: [],
      },
    ],
  },
  road: {
    name: "Road",
    role: "laborer",
    material: "rubble",
    value: 1,
    effects: [
      {
        description: "When adding to STONE STRUCTURE may use any material",
        type: "modifier",
        affects: ["craftsman", "architect"],
        depends: [],
      },
    ],
  },
  archway: {
    name: "Archway",
    role: "legionary",
    material: "brick",
    value: 2,
    effects: [
      {
        description:
          "When performing ARCHITECT action may take material from POOL",
        type: "modifier",
        affects: ["architect"],
        depends: ["pool"],
      },
    ],
  },
  // Republic cards
  crane: {
    name: "Crane",
    role: "craftsman",
    material: "wood",
    value: 1,
    effects: [
      {
        description: "You may use any card to lead or follow ARCHITECT",
        type: "modifier",
        affects: ["architect"],
        depends: [],
      },
    ],
  },
  "domus-aurea": {
    name: "Domus Aurea",
    role: "merchant",
    material: "stone",
    value: 3,
    effects: [
      {
        description:
          "When performing LEGIONARY role may tear down matching incomplete building with no materials. Place foundation in your stockpile and site in your influence",
        type: "in-addition",
        affects: ["legionary"],
        depends: [],
      },
    ],
  },
  "forum-romanum": {
    name: "Forum Romanum",
    role: "patron",
    material: "marble",
    value: 3,
    effects: [
      {
        description:
          "You win immediately whenever you have a client of each role and one material of each type in your stockpile",
        type: "modifier",
        affects: ["end-of-game"],
        depends: ["clientele"],
      },
    ],
  },
  tribunal: {
    name: "Tribunal",
    role: "architect",
    material: "concrete",
    value: 2,
    effects: [
      {
        description: "After performing THINKER you may draw one JACK",
        type: "modifier",
        affects: ["thinker"],
        depends: [],
      },
    ],
  },
};

export default orders;
