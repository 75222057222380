import classNames from "classnames";
import React from "react";
import "./ViewTag.css";

function ViewTag({ className, children, selected, defaultSelect }) {
  return (
    <div className={classNames("view-tag", className, {
      selected,
      defaultSelect
    })}>
      {children}
    </div>
  );
}

export default ViewTag;
