import classNames from "classnames";
import React from "react";
import "./PlayerTag.css";
import ViewTag from "./ViewTag";
import { m2color, materials } from "../data/cards";

const d2color = {
  "orders": "#a9532e",
  "jacks": "white",
}

function PlayerTag({
  player,
  selected,
  defaultSelect,
  scoresAccurate,
  material,
}) {
  let {
    leader,
    active,
    self,
    connected,
    player: name,
    score,
    actionsCount,
    clientele,
    vault,
    stockpile,
    action,
    romeDemands,
    hand,
  } = player;
  hand = hand || [];
  clientele = clientele || [];
  stockpile = stockpile || [];
  romeDemands = romeDemands || [];
  vault = vault || [];
  action = action || [];
  const follow = !leader && action.length > 0;
  const showPlayerVisible = false;
  return (
    <ViewTag
      className={classNames("player-tag", material, {
        leader,
        active,
        self,
        connected,
      })}
      selected={selected}
      defaultSelect={defaultSelect}
    >
      <div className="clientele">
        {materials
          .map((m) => [
            m,
            clientele.filter(({ material }) => material === m).length,
          ])
          .map(([m, count]) => (
            <div
              className={classNames("count", m, { empty: count === 0 })}
              style={{ backgroundColor: m2color[m] }}
            >
              {count > 1 ? count < 10 ? count : "9" : " "}
            </div>
          ))}
      </div>
      <div className="stockpile">
        {materials
          .map((m) => [
            m,
            stockpile.filter(({ material }) => material === m).length,
          ])
          .map(([m, count]) => (
            <div
              className={classNames("count", m, { empty: count === 0 })}
              style={{ backgroundColor: m2color[m] }}
            >
              {count > 1 && (
                <span className="digit">
                  {count < 100 ? count : "99"}
                </span>
              )}
            </div>
          ))}
      </div>
      <div className="vault">
        {[...materials, "order"]
          .map((m) => [
            m,
            vault
              .map((c) =>
                c.visibility !== "player" || showPlayerVisible
                  ? c
                  : { name: "order" }
              )
              .filter(
                ({ material, name }) =>
                  material === m || (m === "order" && name === m)
              ).length,
          ])
          .map(([m, count]) => (
            <div
              className={classNames("count", m, { empty: count === 0 })}
              style={{ backgroundColor: m2color[m] }}
            >
              {count > 1 && (
                <span className="digit">
                  {count < 10 ? count : "9"}
                </span>
              )}
            </div>
          ))}
      </div>
      <div className="name">{name}</div>
      <div className="hand">
        {hand.length < 9 ? hand.map(({ deck }) => (
          <div
            className={classNames("one", deck)}
            style={{ backgroundColor: d2color[deck] }}
          >
            {" "}
          </div>
        ))
          :
          ["orders", "jacks"]
            .map((d) => [
              d,
              hand.filter(
                ({ deck }) => deck === d
              ).length,
            ])
            .filter(([_, count]) => count > 0)
            .map(([d, count]) => (
              <div
                className={classNames("count", d)}
                style={{ backgroundColor: d2color[d] }}
              >
                {count > 1 && (
                  <span className="digit">
                    {count < 10 ? count : "9"}
                  </span>
                )}
              </div>
            ))}
      </div>
      <div className="bottom">
        <div className="score">
          {score}{!scoresAccurate && "±?"}
        </div>
        <div className={classNames("status", { leader, follow })}>
          <span className="invisible">.</span>
          {leader && "Leader"}
          {follow && "Follows"}
          {actionsCount > 0 && ` ${actionsCount}`}
        </div>
      </div>
      <div className="action">
        {action.length < 6 ?
          action.map(({ material, name }) => (
            <div
              className={classNames("one", material, name)}
              style={{ backgroundColor: m2color[material || name] }}
            >
              {" "}
            </div>
          ))
          :
          [...materials, "jack"]
            .map((m) => [
              m,
              action.filter(
                ({ material, name }) => material === m || m === name
              ).length,
            ])
            .filter(([_, count]) => count > 0)
            .map(([m, count]) => (
              <div
                className={classNames("count", m, { empty: count === 0 })}
                style={{ backgroundColor: m2color[m] }}
              >
                {count > 1 && (
                  <span className="digit">
                    {count < 10 ? count : "9"}
                  </span>
                )}
              </div>
            ))}
      </div>
      <div className="rome-demands">
        {romeDemands.length < 6 ?
          romeDemands.map(({ material, name }) => (
            <div
              className={classNames("one", material, name)}
              style={{ backgroundColor: m2color[material || name] }}
            >
              {" "}
            </div>
          ))
          :
          [...materials, "jack"]
            .map((m) => [
              m,
              romeDemands.filter(
                ({ material, name }) => material === m || m === name
              ).length,
            ])
            .filter(([_, count]) => count > 0)
            .map(([m, count]) => (
              <div
                className={classNames("count", m, { empty: count === 0 })}
                style={{ backgroundColor: m2color[m] }}
              >
                {count > 1 && (
                  <span className="digit">
                    {count < 10 ? count : "9"}
                  </span>
                )}
              </div>
            ))}
      </div>
    </ViewTag>
  );
}

export default PlayerTag;
