import classNames from "classnames";
import React, { Fragment, useCallback, useState } from "react";
import { materials } from "../../data/cards";
import { cardImageForMaterial } from "../card-images";
import "./Vault.css";

function Vault({ vault, config: { showZero } }) {
  const [showPlayerVisible, setShowPlayerVisible] = useState(false);
  const toggleShowPlayerVisible = useCallback(
    () => setShowPlayerVisible(!showPlayerVisible),
    [showPlayerVisible, setShowPlayerVisible]
  );
  return (
    <div className="mini-Vault" onClick={toggleShowPlayerVisible}>
      {[...materials, "order"]
        .map((m) => [
          m,
          (vault || [])
            .map((c) =>
              c.visibility !== "player" || showPlayerVisible
                ? c
                : { name: "order" }
            )
            .filter(
              ({ material, name }) =>
                material === m || (m === "order" && name === m)
            ).length,
        ])
        .filter(([_, count]) => count > 0 || showZero)
        .map(([m, count], index, all) => (
          <Fragment key={m}>
            <div
              className={classNames("tag", cardImageForMaterial(m), {
                first: index === all.length - 1,
              })}
              style={{ gridColumn: all.length - index, gridRow: 1 }}
            />
            <div
              className="value"
              style={{ gridColumn: all.length - index, gridRow: 2 }}
            >
              {count}
            </div>
          </Fragment>
        ))}
    </div>
  );
}

export default Vault;
