import classNames from "classnames";
import React from "react";
import { MemoizedCardTooltip } from "../Card";
import { cardImageForBuilding } from "../card-images";
import "./IncompleteBuildings.css";

function IncompleteBuildings({ buildings }) {
  return (
    <div className="mini-IncompleteBuildings">
      {Object.values(buildings ?? {}).map((v, index, arr) => {
        if (v.length < 2) return null;
        const value = v[1].n;
        const name = v[0].name;
        const material = v[1].material;
        const completed = v.length - 2;
        return (
          <MemoizedCardTooltip key={index} name={name} material={material}>
            <div className="row">
              <div
                className={classNames("tag", cardImageForBuilding(v[0].name), {
                  last: index === arr.length - 1,
                })}
              />
              <div className="value">
                {completed}/{value}
              </div>
            </div>
          </MemoizedCardTooltip>
        );
      })}
    </div>
  );
}

export default IncompleteBuildings;
