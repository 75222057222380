import classNames from "classnames";
import React, { Fragment } from "react";
import { cardImageForDeck } from "../card-images";
import "./ClosedHand.css";

function ClosedHand({ hand, config: { showZero } }) {
  return (
    <div className="mini-ClosedHand">
      {["orders", "jacks"].map((d, index) => {
        const count = (hand || []).filter(({ deck }) => deck === d).length;
        if (count === 0 && !showZero) return null;
        return (
          <Fragment key={d}>
            <div
              className={classNames("tag", cardImageForDeck(d))}
              style={{ gridColumn: 1 + index, gridRow: 1 }}
            />
            <div
              className="value"
              style={{ gridColumn: 1 + index, gridRow: 2 }}
            >
              {count}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}

export default ClosedHand;
