import React, { Fragment } from "react";
import { values, sum } from "lodash";
import { materials } from "../../data/cards";
import "./Sites.css";
import classNames from "classnames";

function Sites({ sites, config: { showZero } }) {
  if (!sites) return null;
  const inTown = sum(values(sites.inTown ?? []));
  return (
    <div className="mini-Sites">
      <div className="sites" style={{ gridRow: 1, gridColumn: 1 }}>
        Sites
      </div>
      <div style={{ gridRow: 2, gridColumn: 1 }}>In {inTown}</div>
      <div style={{ gridRow: 3, gridColumn: 1 }}>Out</div>
      {materials.map((m, index) => {
        if (sites.inTown[m] + sites.outOfTown[m] === 0 && !showZero) {
          return null;
        }
        return (
          <Fragment key={m}>
            <div
              className={classNames("title", m)}
              style={{ gridColumn: 2 + index, gridRow: 1 }}
            />
            <div
              className="in-town value"
              style={{ gridColumn: 2 + index, gridRow: 2 }}
            >
              {sites.inTown[m]}
            </div>
            <div
              className="out-of-town value"
              style={{ gridColumn: 2 + index, gridRow: 3 }}
            >
              {sites.outOfTown[m]}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}

export default Sites;
