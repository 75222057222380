import "./Play.css";
import { connectToGame, setOnMessage } from "./socket/socket.js";
import React, {
  useState,
  useEffect,
  useCallback,
} from "react";
import _ from "lodash";
import PlayLayout from "./PlayLayout";
import PlayLayoutMini from "./ui/mini/PlayLayout";
import { playerBackgrounds } from "./data/constants.js";

const forceMini = false;

const updateGame = (game) => {
  if (!game) {
    return game;
  }
  return {
    ...game,
    timeReceived: Date.now(),
  };
};

function Play({ token, observe }) {
  const [log, setLog] = useState([]);
  const [recentLogStart, setRecentLogStart] = useState(0);
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [socket, setSocket] = useState(null);
  const [game, setGame] = useState(null);
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("Connecting...");
  const [beat, setBeat] = useState(null);
  const [gameOver, setGameOver] = useState(null);
  const [player2material, setPlayer2material] = useState({});
  const updatePlayer2Material = useCallback(
    (game) => {
      if (_.isEmpty(player2material) && game !== null) {
        setPlayer2material(
          _.fromPairs(
            game.players.map((p, index) => [p.player, playerBackgrounds[index]])
          )
        );
      }
    },
    [player2material]
  );

  const onMessage = useCallback(
    (data) => {
      setMessage(null);
      switch (data.type) {
        case "dispatch":
          switch (data.action.action) {
            case "game-over":
              setGameOver(data.action);
              break;
            default:
              break;
          }
          setGame(updateGame(data.game));
          updatePlayer2Material(data.game);
          break;
        case "log":
          setLog([...log, data.message]);
          setGame(updateGame(data.game));
          updatePlayer2Material(data.game);
          break;
        case "announce":
          setLog([...log, data.message]);
          setGame(updateGame(data.game));
          if (data.game.state === "game-over") {
            setGameOver({ scores: data.game.scoresTable });
          }
          updatePlayer2Material(data.game);
          break;
        case "choose":
          setQuestion(data.message);
          setOptions(data.options.filter((o) => o !== "quit"));
          setGame(updateGame(data.game));
          updatePlayer2Material(data.game);
          break;
        case "option":
          setRecentLogStart(log?.length ?? 0);
          setLog([...log, data.message, data.option]);
          setQuestion("");
          setOptions([]);
          break;
        case "update":
          setGame(updateGame(data.game));
          if (data.game.state === "game-over") {
            setGameOver({ scores: data.game.scoresTable });
          }
          updatePlayer2Material(data.game);
          break;
        case "error":
          setError(data.message);
          setGame(null);
          setQuestion("");
          setOptions([]);
          break;
        default:
          throw new Error(`Unexpected data type: ${data.type}`);
      }
    },
    [log, updatePlayer2Material]
  );

  useEffect(() => {
    if (!socket || socket.readyState === WebSocket.CLOSED) {
      setSocket(connectToGame(token, observe));
    }
    return () => {
      socket?.close();
    };
  }, [token, observe, socket, setSocket]);

  useEffect(() => {
    if (socket) {
      setOnMessage(socket, onMessage);
      socket.onopen = () => {
        setConnected(true);
        setError(null);
        setMessage("Connected");
        beat && clearInterval(beat) && setBeat(null);
        setBeat(
          setInterval(() => {
            if (socket && socket.readyState === WebSocket.OPEN) {
              socket.send(JSON.stringify({ type: "beat" }));
            }
          }, 15 * 1000)
        );
      };
      socket.onclose = () => {
        setConnected(false);
        setError("Disconnected");
        setMessage(null);
        beat && clearInterval(beat) && setBeat(null);
      };
      socket.onerror = () => {
        setConnected(false);
        setError("Disconnected");
        setMessage(null);
        beat && clearInterval(beat) && setBeat(null);
      };
    }
  }, [socket, beat, setBeat, onMessage, setConnected, setSocket]);

  const reconnect = useCallback(() => {
    setMessage("Reconnecting...");
    setError(null);
    setSocket(null);
  }, [setSocket]);

  const onOption = useCallback(
    (option) => {
      socket.send(JSON.stringify({ type: "option", option }));
      setQuestion("");
      setOptions([]);
    },
    [socket, setQuestion, setOptions]
  );

  if (window.screen.width < 1000 || forceMini) {
    return (
      <PlayLayoutMini
        log={log}
        game={game}
        player2material={player2material}
        message={message}
        error={error}
        connected={connected}
        reconnect={reconnect}
        question={question}
        options={options}
        onOption={onOption}
        recentLogStart={recentLogStart}
        gameOver={gameOver}
        observe={observe}
      />
    );
  } else {
    return (
      <PlayLayout
        log={log}
        game={game}
        player2material={player2material}
        message={message}
        error={error}
        connected={connected}
        reconnect={reconnect}
        question={question}
        options={options}
        onOption={onOption}
        recentLogStart={recentLogStart}
        gameOver={gameOver}
        observe={observe}
      />
    );
  }
}

export default Play;
