import React, { useState, useEffect, useCallback, useRef } from "react";
import { connectToLobby, setOnMessage } from "./socket/socket.js";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import "./Lobby.css";
import Header from "./ui/Header";
import { devUser } from "./lobby-dev.js";
import PlayerName from "./PlayerName.js";
import { TopPlayers } from "./TopPlayers.js";
import Avatar from "./Avatar.js";

dayjs.extend(relativeTime);

function Lobby({ onPlayGame }) {
  const [socket, setSocket] = useState(null);
  const [lobby, setLobby] = useState(null);
  const [user, setUser] = useState(null);
  const [connected, setConnected] = useState(false);
  const [beat, setBeat] = useState(null);
  const games = user?.games;
  const [recentGames, setRecentGames] = useState(null);
  const [guest, setGuest] = useState(false);

  const playGame = useCallback(
    (id) => {
      console.log("playGame", id);
      onPlayGame && onPlayGame(id);
    },
    [onPlayGame]
  );

  const onMessage = useCallback(
    (data) => {
      switch (data.type) {
        case "update":
          setLobby(data.lobby);
          break;
        case "game-started":
          playGame(data.id);
          break;
        default:
          throw new Error(`Unexpected data: ${data}`);
      }
    },
    [playGame]
  );

  useEffect(() => {
    const s = connectToLobby();
    if (s) {
      setSocket(s);
      return () => {
        s.close();
      };
    }
  }, []);

  useEffect(() => {
    if (socket) {
      setOnMessage(socket, onMessage);
      socket.onopen = () => {
        setConnected(true);
        beat && clearInterval(beat) && setBeat(null);
        setBeat(
          setInterval(() => {
            if (socket && socket.readyState === WebSocket.OPEN) {
              socket.send(JSON.stringify({ type: "beat" }));
            }
          }, 15 * 1000)
        );
      };
      socket.onclose = () => {
        setConnected(false);
        beat && clearInterval(beat);
        setBeat(null);
        setUser(null);
      };
    }
  }, [socket, onMessage, beat]);

  const nameInput = useRef(null);

  const enterLobby = useCallback(() => {
    const name = nameInput.current.value;
    if (name) {
      setUser({ name });
      setGuest(false);
    }
  }, [nameInput]);

  useEffect(() => {
    if (socket && connected && socket.readyState === WebSocket.OPEN) {
      if (user) {
        const { name, googleToken } = user;
        socket.send(JSON.stringify({ type: "login", name, googleToken }));
      } else {
        socket.send(JSON.stringify({ type: "logout" }));
      }
    }
  }, [socket, connected, user]);

  const createGame = useCallback(() => {
    socket.send(JSON.stringify({ type: "create-game" }));
  }, [socket]);

  const joinGame = useCallback(
    (id) => {
      socket.send(JSON.stringify({ type: "join-game", "pending-game-id": id }));
    },
    [socket]
  );

  const toggleRating = useCallback(
    (id) => {
      socket.send(
        JSON.stringify({ type: "toggle-rating", "pending-game-id": id })
      );
    },
    [socket]
  );

  const toggleVariableDecks = useCallback(
    (id) => {
      socket.send(
        JSON.stringify({ type: "toggle-variable-decks", "pending-game-id": id })
      );
    },
    [socket]
  );

  const toggleRepublic = useCallback(
    (id) => {
      socket.send(
        JSON.stringify({ type: "toggle-republic", "pending-game-id": id })
      );
    },
    [socket]
  );

  const handleForumChange = useCallback(
    (pg, forum) => {
      if (pg.settings && pg.settings.forum !== forum) {
        socket.send(
          JSON.stringify({ type: "toggle-forum", "pending-game-id": pg.id })
        );
      }
    },
    [socket]
  );

  const handleBasilicaChange = useCallback(
    (pg, basilica) => {
      if (pg.settings && pg.settings.basilica !== basilica) {
        socket.send(
          JSON.stringify({ type: "toggle-basilica", "pending-game-id": pg.id })
        );
      }
    },
    [socket]
  );

  const handleStartingPoolChange = useCallback(
    (pg, startingPool) => {
      if (pg.settings && pg.settings.startingPool !== startingPool) {
        socket.send(
          JSON.stringify({ type: "toggle-starting-pool", "pending-game-id": pg.id })
        );
      }
    },
    [socket]
  );

  const toggleUndo = useCallback(
    (id) => {
      socket.send(
        JSON.stringify({ type: "toggle-undo", "pending-game-id": id })
      );
    },
    [socket]
  );

  const addBot = useCallback(
    (id) => {
      socket.send(JSON.stringify({ type: "add-bot", "pending-game-id": id }));
    },
    [socket]
  );

  const startGame = useCallback(
    (id) => {
      socket.send(
        JSON.stringify({ type: "start-game", "pending-game-id": id })
      );
    },
    [socket]
  );

  const getGames = async (idToken) => {
    const games = JSON.parse(
      await (await fetch("/games?" + new URLSearchParams({ idToken }))).text()
    );
    setUser((user) => {
      if (user?.googleToken === idToken) {
        return { ...user, games };
      } else {
        return user;
      }
    });
  };

  const onSignIn = useCallback(async (googleUser) => {
    const profile = googleUser.getBasicProfile();
    const name = profile.getGivenName();
    const idToken = googleUser.getAuthResponse().id_token;
    setUser({ name, googleToken: idToken });
    getGames(idToken);
  }, []);

  const observe = useCallback((game) => {
    window.location = "?" + new URLSearchParams({ observe: game.id }).toString();
  }, []);

  const onSignOut = useCallback(async () => {
    await window.gapi?.auth2.getAuthInstance().signOut();
    setUser(null);
  }, []);

  const https = window.location.protocol === "https:";

  const exitLobby = useCallback(() => {
    https && user?.googleToken && onSignOut();
    setUser(null);
  }, [https, user, onSignOut]);

  useEffect(
    () =>
      !user &&
      connected &&
      https &&
      window.gapi?.signin2.render("g-signin", { onsuccess: onSignIn }),
    [user, https, connected, onSignIn]
  );

  const resumeGame = (token) => {
    window.location = "?" + new URLSearchParams({ token }).toString();
  };

  useEffect(() => {
    const loadRecentGames = async () => {
      const resp = await fetch("/games");
      if (resp.ok) {
        setRecentGames(JSON.parse(await resp.text()));
      }
    };
    loadRecentGames();
  }, []);

  const dev = window.location.hostname === "localhost";

  return (
    <div className="lobby">
      <Header showTopPlayers={true} />
      {!user && (
        <div className="login">
          <div className="google-sign-in">
            {connected && https && <div id="g-signin" />}
            {connected && dev && (
              <button onClick={() => onSignIn(devUser)}>Sign In</button>
            )}
            {connected && https && user?.googleToken && (
              <button onClick={onSignOut}>Sign out</button>
            )}
          </div>
          {guest && (
            <form onSubmit={enterLobby} disabled={!connected}>
              <p>Enter your name to play:</p>
              <p>
                <input
                  type="text"
                  ref={nameInput}
                  onSubmit={enterLobby}
                  required
                />
              </p>
              <button onClick={enterLobby} disabled={!connected}>
                Enter Lobby
              </button>
            </form>
          )}
          {!guest && (
            <button onClick={() => setGuest(true)} className="play-as-guest">
              Play as guest
            </button>
          )}
        </div>
      )}
      {user && (
        <>
          <p>Welcome, {user.name}!</p>
          <div>
            <button onClick={exitLobby} disabled={!connected}>
              {user?.googleToken ? "Sign out" : "Exit Lobby"}
            </button>
          </div>
        </>
      )}
      <div id="support-me">
        <a
          title="Support me"
          className="kofi-button"
          href="https://ko-fi.com/V7V0I8TFG"
          target="_blank"
          rel="noreferrer"
        >
          <span className="kofitext">
            <img
              src="https://storage.ko-fi.com/cdn/cup-border.png"
              alt="Ko-fi donations"
              className="kofiimg"
            />
            Support Me
          </span>
        </a>
      </div>
      <div className="join-us-on-discord">
        Join us on{" "}
        <a href="https://discord.gg/2M9PUDS5vR" className="discord">
          Discord
        </a>{" "}
        to find opponents for the game.
      </div>

      {user && (
        <>
          <div className="players-tray">
            <h2>Players in the Lobby</h2>
            <div className="players">
              {lobby &&
                lobby.players.map((p) =>
                  <div key={p.name}
                    className="player"
                    title={`Rating: ${p.rating}`}
                  >
                    <Avatar rating={p.rating} />
                    <div className="name">{p.name}</div>
                  </div>)}
            </div>
          </div>
          <p>Choose a room to play</p>
          <div className="rooms-tray">
            <h2>Rooms</h2>
            <div className="rooms">
              {lobby &&
                lobby.pendingGames.map((pg) => (
                  <div className="room" key={pg.id}>
                    <div className="owner">
                      <span className="label">Owner:</span>{" "}
                      <span className="value">{pg.owner.name}</span>
                    </div>
                    <div className="created-at">
                      <span className="label">Created:</span>{" "}
                      <span className="value" title={pg.createdAt}>
                        {dayjs(pg.createdAt).fromNow()}
                      </span>
                    </div>
                    <div className="players">
                      <span className="label">
                        Players ({pg.players.length}):
                      </span>{" "}
                      <span className="value">
                        {pg.players.map((p) => p.name).join(", ")}
                      </span>
                    </div>
                    <p>
                      <small>
                        {pg.waitingForOtherPlayers &&
                          "Waiting for other players or bots to join."}
                        {pg.gameFull && "Room is full."}
                      </small>
                    </p>
                    <div className="settings" title={pg.changeSettingsHint}>
                      <div className="checkbox-option">
                        <input
                          type="checkbox"
                          id={`republic-${pg.id}`}
                          name="republic"
                          checked={pg.settings?.republic}
                          onChange={() => toggleRepublic(pg.id)}
                          disabled={!connected || !pg.canChangeSettings}
                        />
                        <label
                          htmlFor={`republic-${pg.id}`}
                          disabled={!connected || !pg.canChangeSettings}
                          title="Play with Republic expansion"
                        >
                          Republic
                        </label>
                      </div>
                      <div className="radio-options">
                        <div className="radio-option">
                          <input
                            type="radio"
                            id={`forum-${pg.id}`}
                            name={`forum-${pg.id}`}
                            value="forum"
                            checked={pg.settings?.forum === "forum"}
                            onChange={() => handleForumChange(pg, "forum")}
                            disabled={!connected || !pg.canChangeSettings}
                          />
                          <label
                            htmlFor={`forum-${pg.id}`}
                            disabled={!connected || !pg.canChangeSettings}
                            title="Play with Forum card"
                          >
                            Forum
                          </label>
                        </div>
                        <div className="radio-option">
                          <input
                            type="radio"
                            id={`forum-romanum-${pg.id}`}
                            name={`forum-${pg.id}`}
                            value="forum-romanum"
                            checked={pg.settings?.forum === "forum-romanum"}
                            onChange={() => handleForumChange(pg, "forum-romanum")}
                            disabled={!connected || !pg.canChangeSettings}
                          />
                          <label
                            htmlFor={`forum-romanum-${pg.id}`}
                            disabled={!connected || !pg.canChangeSettings}
                            title="Play with Forum Romanum card"
                          >
                            Forum Romanum
                          </label>
                        </div>
                      </div>
                      <div className="radio-options">
                        <div className="radio-option">
                          <input
                            type="radio"
                            id={`basilica-${pg.id}`}
                            name={`basilica-${pg.id}`}
                            value="basilica"
                            checked={pg.settings?.basilica === "basilica"}
                            onChange={() => handleBasilicaChange(pg, "basilica")}
                            disabled={!connected || !pg.canChangeSettings}
                          />
                          <label
                            htmlFor={`basilica-${pg.id}`}
                            disabled={!connected || !pg.canChangeSettings}
                            title="Basilica allows to merchant from hand in addition to normal merchant action."
                          >
                            Basilica
                          </label>
                        </div>
                        <div className="radio-option">
                          <input
                            type="radio"
                            id={`basilica-arbitrium-${pg.id}`}
                            name={`basilica-${pg.id}`}
                            value="basilica-arbitrium"
                            checked={pg.settings?.basilica === "basilica-arbitrium"}
                            onChange={() => handleBasilicaChange(pg, "basilica-arbitrium")}
                            disabled={!connected || !pg.canChangeSettings}
                          />
                          <label
                            htmlFor={`basilica-arbitrium-${pg.id}`}
                            disabled={!connected || !pg.canChangeSettings}
                            title="Basilica Arbitrium allows to merchant from hand instead of a stockpile."
                          >
                            Basilica Arbitrium
                          </label>
                        </div>
                      </div>
                      <div className="radio-options">
                        <div className="radio-option">
                          <input
                            type="radio"
                            id={`default-starting-pool-${pg.id}`}
                            name={`starting-pool-${pg.id}`}
                            value="default"
                            checked={pg.settings?.startingPool === "default"}
                            onChange={() => handleStartingPoolChange(pg, "default")}
                            disabled={!connected || !pg.canChangeSettings}
                          />
                          <label
                            htmlFor={`default-starting-pool-${pg.id}`}
                            disabled={!connected || !pg.canChangeSettings}
                            title="Play with default random starting pool and the order of players"
                          >
                            Default starting pool
                          </label>
                        </div>
                        <div className="radio-option">
                          <input
                            type="radio"
                            id={`starting-pool-equilibrium-${pg.id}`}
                            name={`starting-pool-${pg.id}`}
                            value="equilibrium"
                            checked={pg.settings?.startingPool === "equilibrium"}
                            onChange={() => handleStartingPoolChange(pg, "equilibrium")}
                            disabled={!connected || !pg.canChangeSettings}
                          />
                          <label
                            htmlFor={`starting-pool-equilibrium-${pg.id}`}
                            disabled={!connected || !pg.canChangeSettings}
                            title="Players discard cards to form starting pool and the order of players"
                          >
                            Equilibrium starting pool
                          </label>
                        </div>
                      </div>
                      <div className="checkbox-option">
                        <input
                          type="checkbox"
                          id={`rating-${pg.id}`}
                          name="rating"
                          checked={pg.settings?.rating}
                          onChange={() => toggleRating(pg.id)}
                          disabled={!connected || !pg.canChangeSettings}
                        />
                        <label
                          htmlFor={`rating-${pg.id}`}
                          disabled={!connected || !pg.canChangeSettings}
                          title="Players rating will be updated after the game"
                        >
                          Rating
                        </label>
                      </div>
                      <div className="checkbox-option">
                        <input
                          type="checkbox"
                          id={`variable-decks-${pg.id}`}
                          name="variable-decks"
                          checked={pg.settings?.variableDecks}
                          onChange={() => toggleVariableDecks(pg.id)}
                          disabled={!connected || !pg.canChangeSettings}
                        />
                        <label
                          htmlFor={`variable-decks-${pg.id}`}
                          disabled={!connected || !pg.canChangeSettings}
                          title="Shrink/grow the deck sizes based on the number of players (enables up to 8 players)"
                        >
                          Variable decks
                        </label>
                      </div>
                      <div className="checkbox-option">
                        <input
                          type="checkbox"
                          id={`undo-${pg.id}`}
                          name="undo"
                          checked={pg.settings?.undo}
                          onChange={() => toggleUndo(pg.id)}
                          disabled={!connected || !pg.canChangeSettings}
                        />
                        <label
                          htmlFor={`undo-${pg.id}`}
                          disabled={!connected || !pg.canChangeSettings}
                          title="Enables undo button"
                        >
                          Undo
                        </label>
                      </div>
                    </div>
                    <div className="buttons">
                      <button
                        onClick={() => joinGame(pg.id)}
                        disabled={!connected || !pg.canJoin}
                      >
                        Join
                      </button>
                      <button
                        onClick={() => addBot(pg.id)}
                        disabled={!connected || !pg.canAddBot}
                        title={pg.addBotHint}
                      >
                        Add Bot
                      </button>
                      <button
                        onClick={() => startGame(pg.id)}
                        disabled={!connected || !pg.canStart}
                        title={pg.startHint}
                      >
                        Start
                      </button>
                      <button
                        onClick={() => playGame(pg.startedGameId)}
                        disabled={!connected || pg.state !== "started"}
                        title={
                          pg.state !== "started"
                            ? "Game is not started yet."
                            : undefined
                        }
                      >
                        Play
                      </button>
                    </div>
                  </div>
                ))}
            </div>
            <div className="buttons">
              <button onClick={createGame} disabled={!user || !connected}>
                Create Room
              </button>
            </div>
          </div>
        </>
      )}
      {!user && (
        <div className="top-players-tray">
          <TopPlayers showFilters={false} />
        </div>
      )}
      {(games || recentGames) && (
        <div className="games-tray">
          {games && (
            <>
              <h2>Your games</h2>
              <div className="past-games">
                {games.map((game, index) => (
                  <div className="past-game" key={index}>
                    <div>
                      <span className="label">Created:</span>{" "}
                      <span title={game.createdAt}>
                        {dayjs(game.createdAt).fromNow()}
                      </span>
                    </div>
                    <div>
                      <span className="label">Played:</span>{" "}
                      <span title={game.updatedAt}>
                        {dayjs(game.updatedAt).fromNow()}
                      </span>
                    </div>
                    <div>
                      <span className="label">State:</span>{" "}
                      <span>{game.state}</span>
                    </div>
                    <div>
                      <span className="label">Players:</span>{" "}
                      {game.lobbyPlayers.map((lp, index, all) => (
                        <span key={lp.name}>
                          <PlayerName userHandle={lp.userHandle} name={lp.name} />
                          {index < all.length - 1 ? ", " : null}
                        </span>
                      ))}
                    </div>
                    <div className="buttons">
                      <button
                        onClick={() => resumeGame(game.playerToken)}
                        disabled={!connected || game.state === "Finished"}
                      >
                        Resume
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          {recentGames && (
            <>
              <h2>Recent games</h2>
              <div className="recent-games">
                {recentGames.map((game, index) => (
                  <div className="recent-game" key={index}>
                    <div>
                      <span className="label">Created:</span>{" "}
                      <span>{dayjs(game.createdAt).fromNow()}</span>
                    </div>
                    <div>
                      <span className="label">Played:</span>{" "}
                      <span>{dayjs(game.updatedAt).fromNow()}</span>
                    </div>
                    <div>
                      <span className="label">State:</span>{" "}
                      <span>{game.state}</span>
                    </div>
                    <div>
                      <span className="label">Players:</span>{" "}
                      {game.lobbyPlayers.map((lp, index, all) => (
                        <span key={lp.name}>
                          <PlayerName userHandle={lp.userHandle} name={lp.name} />
                          {index < all.length - 1 ? ", " : null}
                        </span>
                      ))}
                    </div>
                    <div className="buttons">
                      <button onClick={() => observe(game)}>
                        Observe
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Lobby;
