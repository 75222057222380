import classNames from "classnames";
import React from "react";
import { MemoizedCardTooltip } from "../Card";
import { cardImageForBuilding } from "../card-images";
import "./CompletedBuildings.css";

function CompletedBuildings({ buildings }) {
  return (
    <div className="mini-CompletedBuildings">
      {Object.values(buildings ?? {}).map((v, index, arr) => {
        const material = v[1].material;
        return (
          <MemoizedCardTooltip key={index} name={v[0].name} material={material}>
            <div
              className={classNames("tag", cardImageForBuilding(v[0].name), {
                last: index === arr.length - 1,
              })}
            />
          </MemoizedCardTooltip>
        );
      })}
    </div>
  );
}

export default CompletedBuildings;
