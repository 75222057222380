import classNames from "classnames";
import React from "react";
import orders from "../../data/orders";
import { cardImageForBuilding } from "../card-images";
import "./Action.css";

function Card({ card }) {
  const order = orders[card.name];
  return (
    <div className={classNames("mini-Card", cardImageForBuilding(card.name))}/>
  );
}

function Action({ cards }) {
  if (!cards?.length) {
    return null;
  } else {
    return (
      <div className="mini-Action" title="Action Cards">
        {cards.map((card, index) => (
          <Card
            key={(card.deck ?? "") + (card.id ?? index)}
            card={card}
          />
        ))}
      </div>
    );
  }
}

export default Action;
