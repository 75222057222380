import classNames from "classnames";
import React, { Fragment } from "react";
import { materials } from "../../data/cards";
import { cardImageForMaterial } from "../card-images";
import "./Clientele.css";

function Clientele({ clientele, config: { showZero } }) {
  return (
    <div className="mini-Clientele">
      {materials
        .map((m) => [
          m,
          (clientele || []).filter(({ material }) => material === m).length,
        ])
        .filter(([_, count]) => count > 0 || showZero)
        .map(([m, count], index, all) => (
          <Fragment key={m}>
            <div
              className={classNames("tag", cardImageForMaterial(m), {
                last: index === all.length - 1,
              })}
              style={{ gridColumn: 1 + index, gridRow: 1 }}
            />
            <div
              className="value"
              style={{ gridColumn: 1 + index, gridRow: 2 }}
            >
              {count}
            </div>
          </Fragment>
        ))}
    </div>
  );
}

export default Clientele;
