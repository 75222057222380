import "./PlayLayout.css";
import React, { useState } from "react";
import Game from "./ui/Game";
import GameOver from "./ui/GameOver";
import Header from "./ui/Header";
import ViewSelector from "./ui/ViewSelector";
import LogPanel from "./ui/LogPanel";
import ControlPanel from "./ui/ControlPanel";
import classNames from "classnames";

function PlayLayout({
  log,
  game,
  player2material,
  message,
  error,
  connected,
  reconnect,
  question,
  options,
  onOption,
  recentLogStart,
  gameOver,
  observe,
}) {
  const [hoveredTag, setHoveredTag] = useState(null);
  const [defaultTag, setDefaultTag] = useState(null);
  const selectedTag = hoveredTag ?? defaultTag
    ?? (observe ? game?.players[0].player : game?.player);

  return (
    <div className={classNames("PlayLayout", { observe })}>
      <Header />
      <ViewSelector
        game={game}
        selectedTag={selectedTag}
        defaultTag={defaultTag}
        setHoveredTag={setHoveredTag}
        setDefaultTag={setDefaultTag}
        player2material={player2material}
      />
      <Game
        game={game}
        selectedTag={selectedTag}
        defaultTag={defaultTag}
        setHoveredTag={setHoveredTag}
        setDefaultTag={setDefaultTag}
      />
      {!observe && (
        <ControlPanel
          message={message}
          error={error}
          connected={connected}
          reconnect={reconnect}
          question={question}
          options={options}
          onOption={onOption}
          settings={game?.settings}
        />
      )}
      <LogPanel
        log={log}
        recentLogStart={recentLogStart}
        player2material={player2material}
      />
      {gameOver && <GameOver scores={gameOver.scores} />}
    </div>
  );
}

export default PlayLayout;
