import classNames from "classnames";
import React, {useState, useEffect} from "react";
import Clientele from "./Clientele";
import CompletedBuildings from "./CompletedBuildings";
import ClosedHand from "./ClosedHand";
import IncompleteBuildings from "./IncompleteBuildings";
import "./Player.css";
import Stockpile from "./Stockpile";
import Vault from "./Vault";
import Label from "../Label";
import Action from "./Action";
import RomeDemands from "./RomeDemands";

function Player({ player, material, timeReceived, scoresAccurate, config }) {
  const {
    player: name,
    hand,
    stockpile,
    clientele,
    vault,
    incompleteBuildings,
    completedBuildings,
    action,
    actionsCount,
    influence,
    maxHandSize,
    clienteleSize,
    vaultSize,
    score,
    leader,
    active,
    self,
    romeDemands,
    connected,
    timeUsed,
  } = player;
  const [time, setTime] = useState(Date.now());
  useEffect(() => {
    if (active) {
      setTime(Date.now());
      const i = setInterval(() => setTime(Date.now()), 1000);
      return () => clearInterval(i);
    }
  }, [active, setTime]);
  if (!player) return null;
  const elapsedTime = Math.max(
    0,
    ((active ? time - timeReceived : 0) + timeUsed) / 1000
  );
  let h = Math.floor(elapsedTime / 60 / 60);
  let m = Math.floor(elapsedTime / 60) % 60;
  let s = Math.floor(elapsedTime % 60);
  return (
    <div className={classNames("mini-Player", { self })}>
      <div className="name">
        <Label className={material}>{name}</Label>
        <div className="details">
          {leader && <div className="leader">Leader</div>}
          {!!actionsCount && <div className="actionsCount">Actions: {actionsCount}</div>}
          {!connected && <div className="disconnected">Disconnected</div>}
          <div className="time-used">
            {h}:{String(m).padStart(2, "0")}:{String(s).padStart(2, "0")}
          </div>
        </div>
      </div>
      <Action cards={action} />
      <RomeDemands cards={romeDemands} />
      <div className="influence">Influence: {influence}</div>
      <div className="hand-label">
        Hand: {hand?.length ?? 0}/{maxHandSize}
      </div>
      <div className="score">
        Score: {score}
        {!scoresAccurate && "±?"}
      </div>
      <div className="clientele-hand-vault">
        <Clientele clientele={clientele} config={config} />
        <ClosedHand hand={hand} config={config} />
        <Vault vault={vault} config={config} />
      </div>
      <Stockpile stockpile={stockpile} config={config} />
      <div className="clientele-label">
        Clientele: {clientele?.length ?? 0}/{clienteleSize}
      </div>
      <div className="stockpile-label">Stockpile: {stockpile?.length ?? 0}</div>
      <div className="vault-label">
        Vault: {vault?.length ?? 0}/{vaultSize}
      </div>
      <IncompleteBuildings buildings={incompleteBuildings} config={config} />
      <CompletedBuildings buildings={completedBuildings} config={config} />
    </div>
  );
}

export default Player;
