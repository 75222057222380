import classNames from "classnames";
import React from "react";
import "./PlayerName.css";

const UserName = ({ userHandle, name, guest }) => (
  <a
    className={classNames(
      "player-name",
      { "human-user": userHandle, guest })}
    key={name}
    href={userHandle ? `/user/${encodeURIComponent(userHandle)}` : undefined}
  >
    {name}
    {guest && (
      <span className="label"> (guest)</span>
    )}
  </a>
);

export default UserName;
