import React, { useCallback, useState } from "react";
import "./GameOver.css";

function GameOver({ scores }) {
  const [visible, setVisible] = useState(true);
  const hide = useCallback(() => setVisible(false), []);
  if (!visible) {
    return null;
  }
  return (
    <div className="game-over">
      <button className="close" onClick={hide}>&#10005;</button>
      <h1>Game Over</h1>
      <table className="scores">
        <thead>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th>Forum?</th>
            <th>VPs</th>
            <th>Cards</th>
            <th>Rating+</th>
          </tr>
        </thead>
        <tbody>
          {scores.map(
            (
              {
                place,
                player,
                vps,
                cs,
                "forumWinner?": forumWinner,
                "rating+": rating,
                userHandle,
              },
              index
            ) => (
              <tr key={index}>
                <td>{place}</td>
                <td>
                  <a href={userHandle
                    ? `/user/${encodeURIComponent(userHandle)}`
                    : undefined}>
                    {player}
                  </a>
                </td>
                <td>{forumWinner && "V"}</td>
                <td>{vps}</td>
                <td>{cs}</td>
                <td>{rating}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
}

export default GameOver;
