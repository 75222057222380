import React, { useRef, useLayoutEffect } from "react";
import LogEntry from "./LogEntry";
import "./LogPanel.css";

let timeout;

function LogPanel({ log, recentLogStart, player2material }) {
  const logDiv = useRef(null);

  useLayoutEffect(() => {
    // Clear the timeout if it's already set
    clearTimeout(timeout);

    if (logDiv.current) {
      // Set a new timeout to update the scroll
      timeout = setTimeout(() => {
        logDiv.current.scrollTop = logDiv.current.scrollHeight;
      }, 100); // Adjust the timeout duration (100ms here) as needed
    }
  }, [log, logDiv]);

  return (
    <div className="LogPanel">
      <div ref={logDiv} className="log">
        {log?.slice(0, recentLogStart).map((msg, index) => (
          <LogEntry
            key={index}
            msg={msg}
            old
            player2material={player2material}
          />
        ))}
        <div className="log-separator" />
        {log?.slice(recentLogStart).map((msg, index) => (
          <LogEntry
            key={index + recentLogStart}
            msg={msg}
            player2material={player2material}
          />
        ))}
      </div>
    </div>
  );
}

export default LogPanel;
