export const materials = [
  "rubble",
  "wood",
  "brick",
  "concrete",
  "stone",
  "marble",
];

export const material2role = {
  rubble: "laborer",
  wood: "craftsman",
  brick: "legionary",
  concrete: "architect",
  stone: "merchant",
  marble: "patron",
};

export const material2color = {
  rubble: "#feff05",
  wood: "#deb887",
  brick: "#fe6247",
  concrete: "#788899",
  stone: "#426ae1",
  marble: "#dd9fdc",
};

export const m2color = {
  ...material2color,
  wood: "#c47451",
  marble: "#cb5bca",
  order: "white",
  jack: "white",
};

export const roles = materials.map((m) => material2role[m]);

export const option2description = {
  laborer: [
    {
      title: "Gather Material",
      description: "Move card from POOL into STOCKPILE",
    },
  ],
  patron: [
    {
      title: "Hire Client",
      description: "Move card from POOL into CLIENTELE",
    },
  ],
  architect: [
    {
      title: "Lay Foundation",
      description: "Move card from HAND into new SITE to start new STRUCTURE",
    },
    {
      title: "Build Structure",
      description: "Move card from STOCKPILE into STRUCTURE",
    },
  ],
  craftsman: [
    {
      title: "Lay Foundation",
      description: "Move card from HAND into new SITE to start new STRUCTURE",
    },
    {
      title: "Build Structure",
      description: "Move card from HAND into STRUCTURE",
    },
  ],
  legionary: [
    {
      title: "Demand Material",
      description: "Demand card from Neighbors' HANDs and POOL into STOCKPILE",
    },
  ],
  merchant: [
    {
      title: "Sell Material",
      description: "Move card from STOCKPILE into VAULT",
    },
  ],
  jack: [
    {
      title: "JACK",
      description: "Play a JACK to lead or follow any role",
    },
  ],
  petition2: [
    {
      title: "PETITION",
      description: "Play two cards of same role as JACK",
    },
  ],
  petition3: [
    {
      title: "PETITION",
      description: "Play three cards of same role as JACK",
    },
  ],
  thinker: [
    {
      title: "THINKER",
      description: "Draw from DECK to max HAND size",
    },
    {
      description: "Draw one card",
    },
    {
      description: "Draw one JACK",
    },
  ],
  orders: [
    {
      description: "Draw from DECK to max HAND size",
    },
    {
      description: "Draw one card",
    },
  ],
};
