import React from "react";
import "./PoolTag.css";
import ViewTag from "./ViewTag";
import { m2color, materials } from "../data/cards";
import classNames from "classnames";

function PoolTag({ pool, sites, deck, jacks, selected, defaultSelect }) {
  sites = sites || { inTown: {}, outOfTown: {} };
  pool = pool || [];
  return (
    <ViewTag
      className="pool-tag"
      selected={selected}
      defaultSelect={defaultSelect}
    >
      <div className="pool">
        {pool.length < 6 ?
          pool.map(({ material, name }) => (
            <div
              className={classNames("one", material, name)}
              style={{ backgroundColor: m2color[material || name] }}
            >
              {" "}
            </div>
          ))
          : [...materials, "order"]
            .map((m) => [
              m,
              pool
                .filter(
                  ({ material, name }) =>
                    material === m || (m === "order" && name === m)
                ).length,
            ])
            .filter(([_, count]) => count > 0)
            .map(([m, count]) => (
              <div
                className={classNames("count", m, { empty: count === 0 })}
                style={{ backgroundColor: m2color[m] }}
              >
                {count > 1 && (
                  <span className="digit">
                    {count < 10 ? count : "9"}
                  </span>
                )}
              </div>
            ))}
      </div>
      <div>Deck: {deck}</div>
      <div>Jacks: {jacks}</div>
      <div className="sites">
        {materials
          .filter((m) => sites.inTown[m] + sites.outOfTown[m] > 0)
          .map((m) => (
            <div className={classNames("site", m)}>
              <div
                className={classNames("out-of-town",
                  "count",
                  m,
                  {
                    empty: sites.outOfTown[m] === 0,
                    "fully-visible": sites.inTown[m] === 0
                  })}
                style={{
                  backgroundColor: m2color[m],
                }}
              >
                {sites.outOfTown[m] > 1 && (
                  <span className="digit">
                    {sites.outOfTown[m]}
                  </span>
                )}
              </div>
              <div
                className={classNames("in-town", "count", m, { empty: sites.inTown[m] === 0 })}
                style={{ backgroundColor: m2color[m] }}
              >
                {sites.inTown[m] > 1 && (
                  <span className="digit">
                    {sites.inTown[m]}
                  </span>
                )}
              </div>
            </div>
          ))}
      </div>
    </ViewTag>
  );
}

export default PoolTag;
