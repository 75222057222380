import React from "react";
import "./Game.css";
import Player from "./Player.js";
import Card from "./Card.js";
import Cards from "./Cards.js";
import { materials } from "../data/cards";
import classNames from "classnames";
import { POOL } from "../data/constants";

function Game({ game, selectedTag }) {
  return (
    game && (
      <div className="game">
        <div className={`players players-${game.players.length}`}>
          <div
            className={classNames("view-area", "pool", {
              selected: selectedTag === POOL,
            })}
          >
            <div className="pool">
              Pool: <Cards cards={game.pool} emptyDiv={true} threshold={8}/>
            </div>
            <div className="orders-deck">
              Orders: {game.ordersDeck} Jacks: {game.jacksDeck}
            </div>
            <div className="sites">
              {materials.map((m) => (
                <div key={m} className="site">
                  {new Array(game.sites.outOfTown[m])
                    .fill(1)
                    .map((_, index) => (
                      <Card key={m + "O" + index} card={{ name: `${m} out-of-town site` }} />
                    ))}
                  {new Array(game.sites.inTown[m]).fill(1).map((_, index) => (
                    <Card
                      key={m + "I" + index}
                      card={{ name: `${m} site`, material: m, id: m }}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
          {game.players.map((p, index) => (
            <div
              key={p.player}
              className={classNames("view-area", "player", {
                self: p.self,
                top: index === 0,
                selected: selectedTag === p.player,
              })}
            >
              <Player
                player={p}
                role={game.role}
                timeReceived={game.timeReceived}
                scoresAccurate={game.scoresAccurate}
              />
            </div>
          ))}
        </div>
      </div>
    )
  );
}

export default Game;
