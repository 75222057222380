const devBasicProfile = {
  getGivenName() {
    return "dev";
  }
}

const devUser = {
  getBasicProfile() {
    return devBasicProfile;
  },
  getAuthResponse() {
    return { id_token: "abc" };
  }
}

export { devUser };
