
const rating2role = (rating) => {
  if (rating < 1050) {
    if (rating < 900) { return "laborer"; }
    if (rating < 975) { return "craftsman"; }
    return "legionary";
  } else {
    if (rating < 1125) { return "architect"; }
    if (rating < 1200) { return "merchant"; }
    return "patron";
  }
}

export {rating2role};
