import React, { Fragment } from "react";
import "./Pool.css";
import { materials } from "../../data/cards";
import classNames from "classnames";
import { cardImageForMaterial } from "../card-images";

function Pool({ pool, config: { showZero } }) {
  if (!pool) return null;
  return (
    <div className="mini-Pool">
      <div
        className="pool"
        style={{ gridRowStart: 1, gridRowEnd: 3, gridColumn: 1 }}
      >
        Pool
        <br />
        {pool.length}
      </div>
      {[...materials, "order"]
        .map((m) => [
          m,
          (pool || [])
            .filter(
              ({ material, name }) =>
                material === m || (m === "order" && name === m)
            ).length,
        ])
        .filter(([_, count]) => count > 0 || showZero)
        .map(([m, count], index) => (
          <Fragment key={m}>
            <div
              className={classNames("title", cardImageForMaterial(m))}
              style={{ gridColumn: 2 + index, gridRow: 1 }}
            />
            <div
              className="value"
              style={{ gridColumn: 2 + index, gridRow: 2 }}
            >
              {count}
            </div>
          </Fragment>
        ))
      }
    </div>
  );
}

export default Pool;
