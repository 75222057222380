import React, { useCallback, useEffect, useState } from "react";
import Card from "./Card.js"
import Cards from "./Cards.js"
import classNames from "classnames"
import "./Player.css"

function Player({ player, role, timeReceived, scoresAccurate }) {
  const {
    player: name,
    hand,
    stockpile,
    clientele,
    vault,
    incompleteBuildings,
    completedBuildings,
    action,
    influence,
    maxHandSize,
    clienteleSize,
    vaultSize,
    score,
    leader,
    active,
    self,
    romeDemands,
    connected,
    timeUsed,
  } = player ?? {};
  const [time, setTime] = useState(Date.now());
  useEffect(() => {
    if (active) {
      setTime(Date.now());
      const i = setInterval(() => setTime(Date.now()), 1000);
      return () => clearInterval(i);
    }
  }, [active, setTime]);
  const elapsedTime = Math.max(
    0,
    ((active ? time - timeReceived : 0) + timeUsed) / 1000
  );
  let h = Math.floor(elapsedTime / 60 / 60);
  let m = Math.floor(elapsedTime / 60) % 60;
  let s = Math.floor(elapsedTime % 60);
  const [showPlayerVisible, setShowPlayerVisible] = useState(false);
  const toggleShowPlayerVisible = useCallback(
    () => setShowPlayerVisible(!showPlayerVisible),
    [showPlayerVisible, setShowPlayerVisible]
  );
  return (
    player && (
      <div
        className={classNames("player", { leader, active, self, connected })}
      >
        <div className="player-mat">
          {romeDemands?.length > 0 && (
            <div className="rome-demands">
              <Cards cards={romeDemands} />
            </div>
          )}
          <div className="mat">
            <div className="name">
              {name} {leader && `- leader - ${role ?? "..."}`} {h}:
              {String(m).padStart(2, "0")}:{String(s).padStart(2, "0")}
            </div>
            <div className="influence">
              Influence: {influence} Score: {score}
              {!scoresAccurate && "±?"}
            </div>
            <div className="action">
              <Cards cards={action} />
            </div>
            <div className="hand">
              Hand ({hand?.length ?? 0}/{maxHandSize}):
              <Cards cards={hand} withOverlay={true} />
            </div>
            <div className="stockpile-label">
              Stockpile {stockpile?.length > 0 && `(${stockpile.length})`}
            </div>
            <div className="clientele-label">
              Clientele ({clientele?.length ?? 0}/{clienteleSize})
            </div>
            <div className="vault-label">
              Vault ({vault?.length ?? 0}/{vaultSize})
            </div>
          </div>
          <div className="stockpile">
            <Cards cards={stockpile} show="material" threshold={6} />
          </div>
          <div className="clientele">
            <Cards cards={clientele} show="role" threshold={6} />
          </div>
          <div
            className="vault"
            onMouseEnter={() => toggleShowPlayerVisible(true)}
            onMouseLeave={() => toggleShowPlayerVisible(false)}
          >
            <Cards cards={(vault || []).map((c) =>
              c.visibility !== "player" || showPlayerVisible
                ? { ...c, visibility: "all" }
                : { name: "order" }
            )} show="material" threshold={7} />
          </div>
        </div>
        <div className="buildings">
          <div className="incomplete-buildings">
            {Object.values(incompleteBuildings ?? {}).map((v) => (
              <div className="incomplete-building" key={v[0].name}>
                {v
                  .map((c, i) => (
                    <Card
                      key={i}
                      card={i !== 1 ? c : { ...c, name: `${c.material} site` }}
                      withOverlay={i === 0}
                    />
                  ))
                  .reverse()}
              </div>
            ))}
          </div>
          <div className="completed-buildings">
            <Cards
              cards={Object.values(completedBuildings ?? {}).map((v) => v[0])}
              withOverlay={true}
            />
          </div>
        </div>
      </div>
    )
  );
}

export default Player;
